import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/main.scss";

/**
 * Custom libraries
 */
import Vuelidate from "vuelidate";
import axios from "axios";
import VueAxios from "vue-axios";
import Toasted from "vue-toasted";
import VueSession from "vue-session";
import jQuery from "jquery";

global.jQuery = jQuery;

Vue.use(VueSession);
Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use(Toasted, {
  theme: "outline",
  position: "top-center",
  duration: 5000,
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
